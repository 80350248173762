define("discourse/plugins/discourse-form-processor/discourse/form-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("forms", function () {
      window.console.log("doing a route!");
      this.route("submit", {
        path: "/forms/submit/:id"
      });
    });
    this.route("get", {
      path: ":id"
    });
  }
});